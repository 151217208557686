export const ServiceUrls = {
  UploadFile: 'AWSUploader/Upload',
  Search: 'AWSUploader/Search',
  DeleteFile:'AWSUploader/DeleteFile',
  getFolderInYearAndMonth: 'AWSUploader/GetFoldersInYearAndMonthFormat?websiteId=',
  getSubFolderFiles: 'AWSUploader/GetSubFolderFiles?foldePath=',
  uploadFileAccordingYearAndMonth: 'AWSUploader/UploadFileAccordingToYearAndMonth?websiteId=',
  uploadFileIncurrentFolder: 'AWSUploader/UploadFileInMonthFolderCommand?folderPath=',
  updateFileInformation: 'AWSUploader/UpdateFileInformation'

};
export const LanguageUrls = {
  ListLanguage: 'Language/GetAll',
  GetLanguageById: 'Language/Get',
  CreateLanguage: 'Language/Add',
  UpdateLanguage: 'Language/Update',
  DeleteLanguage: 'Language/Delete',
  DDLLanguage: 'Language/GetDDL',
  GetDefaultLanguage: 'Language/GetDefaultLanguage'
};
export const EnterpriseSectionsUrls = {
  ListEnterpriseSection: 'EnterpriseSection/GetAll',
  GetEnterpriseSectionById: 'EnterpriseSection/Get',
  CreateEnterpriseSection: 'EnterpriseSection/Add',
  UpdateEnterpriseSection: 'EnterpriseSection/Update',
  DeleteEnterpriseSection: 'EnterpriseSection/Delete',

};
export const CountryUrls = {
  ListCountry: 'Country/GetAll',
  GetCountryById: 'Country/Get',
  CreateCountry: 'Country/Add',
  UpdateCountry: 'Country/Update',
  DeleteCountry: 'Country/Delete',
  DDLCountry: 'Country/GetDDL',
  GetCountryEditions: 'Country/GetCountryEditions',
  GetCountryEditionDetails: 'Country/GetCountryEditionDetails',
  AssignCountryEditions: 'Country/AssignCountryEditions',
  UpdateCountryEdition: 'Country/UpdateCountryEdition',
  RemoveCountryEdition: 'Country/RemoveCountryEdition',
  ActivateDeactivcateCountryEdition:
    'Country/ActivateDeactivcateCountryEdition',
};
export const EditionUrls = {
  GetAll: 'Edition/GetAll',
  Get: 'Edition/Get',
  Add: 'Edition/Add',
  Edit: 'Edition/Edit',
  Remove: 'Edition/Remove',
  ActivateDeactivate: 'Edition/ActivateDeactivate',
  GetDDL: 'Edition/GetDDL',
  GetSubscripableEditionDDL: 'Edition/GetSubscripableEditionDDL',
  GetEditionLanguageDDL: 'Edition/GetEditionLanguageDDL',
  GetEditionTypeDDL: 'Edition/GetEditionTypeDDL',
  GetEditionActivites: 'Edition/GetEditionActivities',
  NewGetEditionActivites: 'Edition/NewGetEditionActivities',
  ReOrderItems:"Edition/ReOrderItems"
};
export const FrontendSponserUrls = {
  GetAll: 'FrontendSponser/GetAll',
  Get: 'FrontendSponser/GetById',
  Add: 'FrontendSponser/Add',
  Edit: 'FrontendSponser/Edit',
  Remove: 'FrontendSponser/Remove',
  ActivateDeactivateSponsor: 'FrontendSponser/ActivateDeactivate',
  ReOrderItems:"FrontendSponser/ReOrderItems"
};
export const StoryCategoryUrls = {
  ListStoryCategory: 'StoryCategory/GetAll',
  GetStoryCategoryById: 'StoryCategory/Get',
  CreateAStoryCategory: 'StoryCategory/Add',
  UpdateStoryCategory: 'StoryCategory/Update',
  DeleteStoryCategory: 'StoryCategory/Delete',
  getStoryCategoryDDL: 'StoryCategory/GetStoryCategoryDDL',
};
export const SponsorUrls = {
  ListSponsor: 'Sponsor/GetAll',
  GetSponsorById: 'Sponsor/Get',
  CreateSponsor: 'Sponsor/Add',
  UpdateSponsor: 'Sponsor/Update',
  DeleteSponsor: 'Sponsor/Delete',
  ActivateDeactivateSponsor: 'Sponsor/ActivateDeactivate',
  DDLSponsorAppearanceType: 'Sponsor/GetSponsorAppearanceTypeDDL',
  SetAsDefaultURL: 'Sponsor/SetIsDefaultSponsor',
  GetSponsorDDL: 'Sponsor/GetSponsorDDL'
};

export const SponsorGroupUrls = {
  ListSponsorGroup: 'SponsorGroup/GetAll',
  GetSponsorGroupById: 'SponsorGroup/Get',
  CreateSponsorGroup: 'SponsorGroup/Add',
  UpdateSponsorGroup: 'SponsorGroup/Update',
  DeleteSponsorGroup: 'SponsorGroup/Delete',
  GetSponsorGroupDDL: 'SponsorGroup/GetSponsorGroupDDL',
  AssignToEdition: 'SponsorGroup/AssignToEdition'
};

export const AdUrls = {
  ListAd: 'Ad/GetAll',
  GetAdById: 'Ad/Get',
  CreateAd: 'Ad/Add',
  UpdateAd: 'Ad/Update',
  DeleteAd: 'Ad/Delete',
  ActivateDeactivateAd: 'Ad/ActivateDeactivate',
  DDLAdAppearanceType: 'Ad/GetAdAppearanceTypeDDL',
  SetAsDefaultURL: 'Ad/SetIsDefaultAd',
  getAddDDL: 'Ad/GeAddDDL'
};

export const AuthUrls = {
  LoginUrl: 'Authentication/Login',
  SocialLoginUrl: 'Authentication/SocialLogin',
  RefreshTokenUrl: 'Authentication/RefreshToken'
};
export const SuperTagUrls = {
  ListSuperTag: 'SuperTag/GetAll',
  GetSuperTagById: 'SuperTag/GetById',
  UpdateSuperTag: 'SuperTag/Update',
  DeleteSuperTag: 'SuperTag/Delete',
  CreateSuperTag: 'SuperTag/Add',
  ReOrderItems: 'SuperTag/ReOrderSuperTags'
};
export const AboutUsUrls = {
  ListAboutUs: 'AboutUs/GetAll',
  GetAboutUsById: 'AboutUs/Get',
  CreateAboutUs: 'AboutUs/Add',
  UpdateAboutUs: 'AboutUs/Update',
  DeleteAboutUs: 'AboutUs/Delete',
};
export const TermsAndConditionsUrls = {
  ListTermsAndConditionsUs: 'TermsAndConditions/GetAll',
  GetTermsAndConditionsById: 'TermsAndConditions/Get',
  CreateTermsAndConditionsUs: 'TermsAndConditions/Add',
  UpdateTermsAndConditionsUs: 'TermsAndConditions/Update',
  DeleteTermsAndConditionsUs: 'TermsAndConditions/Delete',
};
export const VacancyUrls = {
  CreateVacancy: 'Vacancy/Add',
  ListVacancy: 'Vacancy/GetAll',
  GetVacancyById: 'Vacancy/Get',
  UpdateVacancy: 'Vacancy/Update',
  DeleteVacancy: 'Vacancy/Delete',
  vacancyDDLUrl: 'Vacancy/GetVacancyDDL',
  ActivateDeactivateVacancy: 'Vacancy/ActivateDeactivate',
  DDLVacancyLocationType : 'Vacancy/GetVacancyLocationTypeDDL',
  DDLVacancyTimeType : 'Vacancy/GetVacancyTimeTypeDDL',
};

export const VacancyCategoryUrls = {
  CreateVacancyCategory: 'VacancyCategory/Add',
  ListVacancyCategory: 'VacancyCategory/GetAll',
  GetVacancyCategoryById: 'VacancyCategory/Get',
  UpdateVacancyCategory: 'VacancyCategory/Update',
  DeleteVacancyCategory: 'VacancyCategory/Delete',
  VacancyCategoryDDLUrl: 'VacancyCategory/GetDDL',
};

export const TagUrls = {
  GetAll: 'Tag/GetAll',
  Get: 'Tag/Get',
  GetDDL: 'Tag/GetDDL',
  ActivateDeactivateTag: 'Tag/ActivateDeactivate',
};
export const ZeroBounceUrls = {
  GetAll: 'ZeroBounce/GetAll',
};
export const SystemTagUrls = {
  GetAll: 'SystemTag/GetAll',
  UpdateDescriptionForSystemTag: 'SystemTag/EditTagDescription',
  UpdateDescriptionForOtherSystemTag: 'OtherSystemTag/EditDescription',
};
export const OtherSystemTagUrls = {
  Add: 'OtherSystemTag/Add',
  Edit: 'OtherSystemTag/Edit',
  Remove: 'OtherSystemTag/Remove?tag=',
  ListOtherSystemTag: 'OtherSystemTag/GetAll'
};
export const HashTagUrls = {
  GetAll: 'HashTag/GetAll',
  Get: 'HashTag/Get',
  GetById: 'HashTag/GetById',
  GetHashTagDDL: 'HashTag/GetHashTagDDL',
  Add: 'HashTag/Add',
  Edit: 'HashTag/Edit',
  Remove: 'HashTag/Remove',
  ActivateDeactivateTag: 'HashTag/ActivateDeactivate',
  DDLHashTag: 'HashTag/GetDDL',
};
export const TranslationUrl = {
  List: 'Translation/GetAll',
  Add : 'Translation/AddTranslation',
  Delete: 'Translation/Delete'
};
export const TicketUrl = {
  TicketList: 'Ticket/GetAll',
  GetTicketUrl: 'Ticket/Get',
  ChangeTicketStatusUrl: 'Ticket/ChangeStatus',
  ticketActionsDDLUrl: 'Ticket/GetTicketActionDDL',
  forwardTicketUrl: 'Ticket/ForwardTicket',
  getLastTicketByMailUrl: 'Ticket/GetLastTicketsByMail'
};
export const ApplicantUrls = {
  ApplicantList: 'Applicant/GetAll',
  GetApplicantById: 'Applicant/Get',
};
export const SiteMenuUrls = {
  ListSiteMenuItem: 'SiteMenuItem/GetAll',
  GetSiteMenuItemById: 'SiteMenuItem/Get',
  CreateSiteMenuItem: 'SiteMenuItem/Add',
  UpdateSiteMenuItem: 'SiteMenuItem/Update',
  DeleteSiteMenuItem: 'SiteMenuItem/Delete',
  ActivateDeactivateSiteMenuItem: 'SiteMenuItem/ActivateDeactivate',
  DDLParentSiteMenuItem: 'SiteMenuItem/GetParentDDL',
  ReOrderSitemenuItems: 'SiteMenuItem/ReOrderSitemenuItems',
};
export const ContactUsUrls = {
  GetContactUs: 'ContactUs/Get',
  UpdateContactUs: 'ContactUs/Update',
  AddContactUs: 'ContactUs/Add',
  DeleteCOntactUs: 'ContactUs/Delete',
  GtAll: 'ContactUs/GetAll',
  ActivateDeavtivateUrl: 'ContactUs/ActivateDeactivate'
};
export const WebsiteLogoUrls = {
  GetWebsiteLogo: 'WebsiteLogo/Get',
  UpdateWebsiteLogo: 'WebsiteLogo/Update',
};
export const PodcastStoryUrls = {
  ListPodcastStory: 'PodcastStory/GetAll',
  CreatePodcastStory: 'PodcastStory/Add',
  UpdatePodcastStory: 'PodcastStory/Update',
  GetPodcastStory: 'PodcastStory/Get',
  DeletePodcastStory: 'PodcastStory/Delete',
  HidePodcastStory: 'PodcastStory/Hide?id=',
  DDLPodcastStory: 'PodcastStory/GetDDL',
};
export const departmentUrls = {
  ListDepartment: 'Department/GetAll',
  GetDepartment: 'Department/Get',
  AddDepartment: 'Department/Add',
  EditDepartment: 'Department/Update',
  DeleteDepartment: 'Department/Delete',
  GetDepartmentDDL: 'Department/GetDepartmentDDL',
};

export const IssueCategoryUrls = {
  ListIssueCategory: 'IssueCategory/GetAll',
  GetIssueCategory: 'IssueCategory/Get',
  AddIssueCategory: 'IssueCategory/Add',
  EditIssueCategory: 'IssueCategory/Update',
  DeleteIssueCategory: 'IssueCategory/Delete',
  GetIssueCategoryDDL: 'IssueCategory/GetIssueCategoryDDL',
};
export const IssueUrls = {
  ListIssue: 'Issue/GetAll',
  GetIssue: 'Issue/Get',
  AddIssue: 'Issue/Add',
  EditIssue: 'Issue/Update',
  DeleteIssue: 'Issue/Delete',
  UnPublishWebIssue: 'Issue/UnPublishWebIssue',
  IssueDDL: 'Issue/GetIssueDDL',
  getIssueDataUrl: 'Issue/GetParsingIssueData',
  getIssueDataWithoutLinkUrl: 'Issue/GetParsingIssueDataWithoutLink',

  scheduleIssueUrl:'Issue/ScheduleIssue',
  checkScheduleIssueAvailabilityUrl:'Issue/CheckScheduleIssueAvailability',
  GetParsingIssueListReportUrl: 'Issue/GetParsingIssueListReport',
  GetParsingIssueReportUrl: 'Issue/GetParsingIssueReport',
  SendIssueMailForTest: 'Issue/SendIssueMailForTest',
  SendIssueMailForTestWithoutLink: 'Issue/SendIssueMailWithoutLinkForTest',

  getMailChimpListsUrl:'MailChimp/GetAllLists',
  getMailChimpTemplatesUrl:'MailChimp/GetAllTemplates',
  getMailChimpTemplateContentUrl:'MailChimp/GetTemplateContent?templateId='
};
export const ReplyTemplateUrls = {
  ListReplyTemplate: 'ReplyTemplate/GetAll',
  GetReplyTemplate: 'ReplyTemplate/Get',
  AddReplyTemplate: 'ReplyTemplate/Add',
  EditReplyTemplate: 'ReplyTemplate/Update',
  DeleteReplyTemplate: 'ReplyTemplate/Delete',
  ReplyTemplateDDL: 'ReplyTemplate/GetReplyTemplateDDL',
};
export const GoogleIntegrationUrls = {
  GetByFolderIdPublisherApi:'GoogleDrive/GetFolderContent',
  GetFileContentByIdPublisherApi:'GoogleDrive/GetContentByFileId'
  
};
export const SubscriptionUrl = {
  getSubscriptionHistory: 'SubscriptionDetails/GetHistory',
  subscribeMailUrl: 'SubscriptionDetails/Subscribe',
  unSubscribeMailUrl: 'SubscriptionDetails/Unsubscribe',
  pauseMailUrl: 'SubscriptionDetails/pause',
  resumeMailUrl: 'SubscriptionDetails/resume',
  updateSubscriptionUrl: 'SubscriptionDetails/UpdateSubscription'
};
export const PodcastEpisodeUrls = {
  ListPodcastEpisode: 'PodcastEpisode/GetAll',
  GetPodcastEpisodeById: 'PodcastEpisode/Get',
  CreatePodcastEpisode: 'PodcastEpisode/Add',
  UpdatePodcastEpisode: 'PodcastEpisode/Update',
  DeletePodcastEpisode: 'PodcastEpisode/Delete',
  HidePodcastEpisode: 'PodcastEpisode/Hide?id=',
  ChangePodcastEpisodeStatusUrl: 'PodcastEpisode/ChangeStatus',
  DDLPodcastEpisode: 'PodcastEpisode/GetDDL',
};
export const AtmoizerUrls = {
  ParseAndSaveIssue: 'Issue/ParseAndSave',
  UpdateIssue: 'Issue/UpdateIssue',
  ParseIssue: 'Issue/Parse',

  ValidateIssue:'Issue/ValidateIssue',

  GetTestMailResult: 'Issue/GetTestMailResult',
  ListIssue: 'Issue/GetAll',
  GetIssue: 'Issue/Get',
  GetValidationSummary: 'Issue/GetValidationSummary',
  GetParsingIssueChild: 'Issue/GetParsingIssueChild',
  PublishIssueWebVersion: 'Issue/PublishIssueToWeb',
  PublishIssueMailVersion: 'Issue/PublishIssueToMail',
  PublishIssueMailVersionWithoutLink: 'Issue/PublishIssueToMailWithoutLink',

  GetMarketPrice: 'Issue/GetMarketPrice?turnOverDate=',
  getMarketPriceStateurl: 'Issue/GetMarketPriceTurnOverState',
  SaveMarketPrice: 'Issue/SaveMarketPrice',
};
export const StoryUrls = {
  ListStory: 'Story/GetAll',
  Get: 'Story/Get',
  Add: 'Story/Add',
  Edit: 'Story/Edit',
  Delete: 'Story/Delete',
  Publish: 'Story/Publish',
  unPublishUrl: 'Story/UnPublish',
  GetAllStoryType: 'Story/GetAllStoryTypes',
  GetPublishedStoryHeadDDL: 'Story/GetPublishedStoryHeadDDL',
  AssignStoryToFronendSection: 'Story/AssignStoryToFronendSection',
  GetIssueStories: 'Story/GetIssueStories',
  GetChildIssueStories: 'Story/GetChildIssueStories',
  UpdateIssueStories: 'Story/UpdateIssueStories',
  GetStoryChild: 'story/GetStoryChild',
  GetStoryLayout: 'story/GetStoryLayout',
  assignStoryToLayout: 'story/assignStoryToLayout',
  ScheduleStory: 'story/ScheduleStory',
  archievedStoryDDL:'story/GetArchievedStoryTypeDDL',
  
};
export const ArchiveStoryUrls = {
  GetArchivedStory : 'ArchiveStory/GetAllArchivedStories',
  GetArchivedStoryById:'ArchiveStory/GetArchivedStoryData',
  EditArchivedStory:'ArchiveStory/UpdateArchivedStory',
}
export const PollUrls = {
  GetAll: 'Poll/GetAll',
  Get: 'Poll/Get',
  Add: 'Poll/Add',
  Edit: 'Poll/Edit',
  Remove: 'Poll/Remove',
  ActivateDeactivate: 'Poll/ActivateDeactivate',
};

export const UserUrls = {
  List: 'User/GetAll',
  Get: 'User/Get',
  Add: 'User/Add',
  Edit: 'User/Edit',
  Delete: 'User/Remove',
  GetUserDDL: 'User/GetUserDDL',
  GetUserInfoUrl: 'User/GetUserDetails?email=',
  GetUserPages: 'User/GetUserPages',
  GetUserPageActions: 'User/GetUserPageActions?pageId='
};

export const DashboardUrls = {

  getPodcastCount: 'PodcastStory/GetPodcastCount',
  GetSitelayoutCounts: 'Dashboard/GetCounts',
  GetIssueCounts: 'Issue/GetIssuesCount',
  GetWebsiteDDL:'Dashboard/GetWebsiteDDL'
};

export const EnterpriseSectionUrls = {
  GetAssignableEnterpriseSectionsUrl: 'StoryCategoryLink/GetStoryCategories',
};
export const StoryCategoryLinkUrls = {
  ReOrderStoryCategoryLinkUrl: 'StoryCategoryLink/ReOrderStorySection',
  DDLStoryCategory: 'StoryCategoryLink/GetStoryCategoryDDL',
  ListStoryCategoryLink: 'StoryCategoryLink/GetAll',
  DeleteStoryCategoryLink: 'StoryCategoryLink/Delete',
  resyncCategoryUrl: 'StoryCategoryLink/SyncStoryCategoryData',

};

export const RoleUrls = {
  GetList: "Role/List",
  GetById: 'Role/Get',
  Add: 'Role/Add',
  Edit: 'Role/Edit',
  Delete: 'Role/Delete',
  GetRoleDDL: 'Role/GetDDL',
  GetRoleActionUrl: 'PageAction/PageActionList',
  GetActionTypeDDL: 'PageAction/GetActionTypeDDL'
}
export const MarketUrls = {
  GetList: "MarketPrice/GetAll",
  GetById: 'MarketPrice/GetById',
  Add: 'MarketPrice/Add',
  Edit: 'MarketPrice/Update',
  Delete: 'MarketPrice/Delete'
}
export const IssueFileUrls = {
  ListIssueFile: 'Issue/GetUploadedIssueFile',
  CreateIssueFile: 'Issue/UploadFile'
};


export const VerticalUrls = {
  ListVertical: 'Vertical/GetAll',
  GetVerticalById: 'Vertical/Get',
  CreateVertical: 'Vertical/Add',
  UpdateVertical: 'Vertical/Edit',
  DeleteVertical: 'Vertical/Delete',
  getVerticalDDL: 'Vertical/GetVerticalDDL'
};
export const FrontEndLayoutUrls = {
  ListLayout: 'FrontEndPageLayout/GetAll',
  GetLayoutById: 'FrontEndPageLayout/GetById',
  AddLayout: 'FrontEndPageLayout/Add',
  UpdateLayout: 'FrontEndPageLayout/Update',
  DeleteLayout: 'FrontEndPageLayout/Delete',
  TemplateTypeDDLUrl: 'FrontEndPageLayout/GetTemplateTypeDDL',
  PageTypeDDLUrl: 'FrontEndPageLayout/GetPageTypeDDL',
  GetEnterpriseSectionTypeDDL: 'FrontEndPageLayout/GetFrontPageSectionTypeDDL',
  SyncLayout: 'FrontEndPageLayout/Sync',
  CopyLayout: 'FrontEndPageLayout/Copy',
  SetDefault: 'FrontEndPageLayout/SetDefault',
  ActivateDeactivate: 'FrontEndPageLayout/ActivateDeactivate',
  LayoutByTypeDDLUrl: 'FrontEndPageLayout/GetLayoutByPageTypeDDL?id=',
  GetLayoutAd: 'FrontEndPageLayout/GetLayoutAd?id=',
  UpdateLayoutAd: 'FrontEndPageLayout/UpdateLayoutAd',
  getLayoutType: 'FrontEndPageLayout/GetLayoutTypeDDL'

};

export const SpecialSectionHeadUrls = {
  GetSpecialSectionHeadById: 'SpecialSectionHead/Get',
  GetSpecialSectionHeadDDL: 'SpecialSectionHead/GetDDL',
  UpdateLocalization: 'SpecialSectionHead/UpdateLocalization'
};

export const CalendarUrls = {
  ListCalendar: 'Calendar/GetAll',
  GetCalendarById: 'Calendar/Get',
  CreateCalendar: 'Calendar/Add',
  UpdateCalendar: 'Calendar/Update',
  DeleteCalendar: 'Calendar/Delete',

};
export const ReferenceDocumentUrls = {
  ListReferenceDocument: 'ReferenceDocument/GetAll',
};
export const StoryTagUrls = {
  ListStoryTag: 'StoryTag/GetAll',
  GetStoryTagById: 'StoryTag/GetById',
  UpdateStoryTag: 'StoryTag/Replace',
  DeleteStoryTag: 'StoryTag/Delete_UnDelete',
  GetStatusDDL: 'StoryTag/GetStoryTagStatusDDL',
  GetVerifiedStoryTagsDDL: 'StoryTag/GetVerifiedStoryTagsDDL',
  GetStoryTagsDDLUrl: 'StoryTag/GetStoryTagsDDL?statusId=',
  VerifyStoryTag: 'StoryTag/Verify',
  ChangeLanguage: 'StoryTag/ChangeLanguage',
  CreateStoryTag: 'StoryTag/Add',
  ReplaceStoryTagList: 'StoryTag/ReplaceList',
  GetTagStoriesList: 'StoryTag/GetSrotiesByTagId',
  Export: 'StoryTag/Export?',
};

export const PageContentUrls = {
  ListPageContent: 'PageContent/GetAll',
  GetPageContentById: 'PageContent/Get',
  CreatePageContent: 'PageContent/AddPageContent',
  UpdatePageContent: 'PageContent/Update',
  DeletePageContent: 'PageContent/Delete',
  FrontendPageDDL: 'PageContent/GeFrontendPageEnumDDL',
};
export const NavBarItemUrls = {
  GetNavbarItemDDL: 'SiteLayout/GetNavbarItemDDL?languageId=',
  GetNavbarItemDDLByParentId: 'SiteLayout/GetNavbarItemDDLByParentId?parentId=',
  GetNavbarItemDetailsByItemId: 'SiteLayout/GetNavbarItemDetailsByItemId?parentId=',
  UpdateNavbarItemDetails: 'SiteLayout/UpdateNavbarItemDetails?navbarItemId='
};
export const CompanyUrls = {
  GetCompanyDDL: 'SiteLayout/GetCompanyDDL?languageId=',
  GetCompanyDetailsByItemId: 'Company/GetCompanyDetailsByItemId?companyId=',
  UpdateCompanyDetails: 'SiteLayout/UpdateCompanyDetails?companyId='
};

